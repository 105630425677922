import { Route } from '@angular/router';

export const PAGES_ROUTES: Route[] = [
    {
        path: 'dashboard',
        title: 'Дашборд',
        data: {
            isNav: true,
            icon: 'home',
            navTitle: 'Дашборд',
            permissionId: 'dashboard',
        },
        loadComponent: () =>
            import('@merchPages/dashboard/dashboard.component').then(
                (m) => m.DashboardComponent
            ),
    },
    {
        path: 'superadmin',
        title: 'Суперадмин',
        data: {
            isNav: true,
            icon: 'crown',
            navTitle: 'Суперадмин',
            permissionId: 'superadmin',
        },
        loadComponent: () =>
            import('@merchPages/superadmin/superadmin.component').then(
                (m) => m.SuperadminComponent
            ),
    },
    {
        path: 'stores',
        title: 'Список аптек',
        data: {
            isNav: true,
            icon: 'pharmacy',
            navTitle: 'Аптеки',
            permissionId: 'stores',
        },
        loadComponent: () =>
            import('@merchPages/stores/stores.component').then(
                (m) => m.StoresComponent
            ),
    },
    {
        path: 'orders',
        title: 'Список заказов',
        data: {
            isNav: true,
            icon: 'basket',
            navTitle: 'Заказы',
            permissionId: 'orders',
        },
        loadComponent: () =>
            import('@merchPages/orders/orders.component').then(
                (m) => m.OrdersComponent
            ),
    },
    {
        path: 'cancels',
        title: 'Отмены',
        data: {
            isNav: true,
            icon: 'cross',
            navTitle: 'Отмены',
            permissionId: 'cancels',
        },
        loadComponent: () =>
            import('@merchPages/cancels/cancels.component').then(
                (m) => m.CancelsComponent
            ),
    },
    {
        path: 'reviews',
        title: 'Отзывы покупателей',
        data: {
            isNav: true,
            icon: 'star',
            navTitle: 'Отзывы',
            permissionId: 'reviews',
        },
        loadComponent: () =>
            import('@merchPages/reviews/reviews.component').then(
                (m) => m.ReviewsComponent
            ),
    },
    {
        path: 'drugs',
        title: 'Собственные торговые марки',
        data: {
            isNav: true,
            icon: 'drugs',
            navTitle: 'СТМ',
            permissionId: 'drugs',
        },
        loadComponent: () =>
            import('@merchPages/drugs/drugs-list/drugs.component').then(
                (m) => m.DrugsComponent
            ),
    },
    {
        path: 'drugs/:id',
        title: 'Редактирование СТМ',
        data: { permissionId: 'drugs' },
        loadComponent: () =>
            import(
                '@merchPages/drugs/drugs-detail/drugs-detail.component'
            ).then((m) => m.DrugsDetailComponent),
    },
    {
        path: 'analytics',
        title: 'Аналитика',
        data: {
            isNav: true,
            icon: 'graph',
            navTitle: 'Аналитика',
            permissionId: 'analytics',
        },
        loadComponent: () =>
            import('@merchPages/analytics/analytics.component').then(
                (m) => m.AnalyticsComponent
            ),
    },
    {
        path: 'tmp-magnit',
        title: 'Временный раздел для Магнита',
        data: {
            isNav: true,
            navTitle: 'Магнит',
            permissionId: 'tmp-magnit',
        },
        loadComponent: () =>
            import('@merchPages/tmp-magnit/tmp-magnit.component').then(
                (m) => m.TmpMagnitComponent
            ),
    },
    {
        path: 'items',
        title: 'Товары',
        data: {
            isNav: true,
            icon: 'figures',
            navTitle: 'Товары',
            permissionId: 'items',
        },
        loadChildren: () =>
            import('@merchPages/items/items.routes').then(
                (mod) => mod.ITEMS_ROUTES
            ),
    },
    {
        path: 'optimus-price',
        title: 'Ценовой робот',
        data: {
            isNav: true,
            icon: 'robot',
            navTitle: 'Робот',
            permissionId: 'pricebot',
        },
        loadChildren: () =>
            import('@merchPages/optimus-price/robot.routes').then(
                (mod) => mod.ROBOT_ROUTES
            ),
    },
    {
        path: 'charms',
        title: 'Мегаплюшки',
        data: {
            isNav: true,
            icon: 'buns',
            navTitle: 'Плюшки',
            permissionId: 'charms',
        },
        loadComponent: () =>
            import('@merchPages/charms/charms.component').then(
                (m) => m.CharmsComponent
            ),
    },
    {
        path: 'logout',
        title: 'Выход',
        data: {
            isNav: true,
            icon: 'exit',
            navTitle: 'Выйти',
            permissionId: 'everyone',
        },
        loadComponent: () =>
            import('@merchPages/logout/logout.component').then(
                (m) => m.LogoutComponent
            ),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/dashboard',
    },
];
